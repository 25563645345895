<template>
  <div class="dashboard-search-card-container">
    <le-card width="100%" height="150px">
      <template>
        <div class="container">
          <div class="title">
            查询结果
            <div class="num-box">{{ dataSource.length }}人</div>
          </div>

          <div class="table-box">
            <a-table :columns="columns" :data-source="dataSource" size="middle">
              <div slot="name" class="name-box" slot-scope="row">
                <a
                  class="name-box"
                  :href="'https://space.bilibili.com/' + row.anchor_uid"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="avatar">
                    <img :src="row.face_url" alt="" srcset="" />
                  </div>
                  <div class="name-uid">
                    <div>{{ row.name }}</div>
                    <div>{{ row.anchor_uid }}</div>
                  </div>
                </a>
              </div>

              <div slot="is_sign" slot-scope="row">
                <div style="color: #4acc7f" v-if="row.is_sign == 0">未签约</div>
                <div style="color: #ff6363" v-else>有工会或官签</div>
              </div>

              <span slot="action">
                <le-button
                  txt="置顶"
                  width="40px"
                  height="30px"
                  fontSize="14px"
                  @btnClick="clickEdit()"
                />
              </span>
            </a-table>
          </div>
        </div>
      </template>
    </le-card>
  </div>
</template>

<script>
const columns = [
  {
    title: "昵称",
    key: "id",
    width: 110,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "状态",
    key: "is_sign",
    width: 100,
    scopedSlots: { customRender: "is_sign" },
  },
  {
    title: "流水（ ￥ ）",
    key: "income",
    width: 100,
    dataIndex: "income",
  },
  {
    title: "直播时长",
    key: "live_time",
    width: 100,
    dataIndex: "live_time",
  },
  {
    title: "操作",
    key: "action",
    width: 110,
    scopedSlots: { customRender: "action" },
  },
];

import tool from "@/utils/tool";
export default {
  components: {},
  data() {
    return {
      columns,
      month: null,
      search: "",
      dataSource: [],
    };
  },
  computed: {},
  mounted() {
    tool.$on("searchOut", (res) => {
      this.search = res;
      this.getList();
    });

    tool.$on("clearOut", () => {
      this.dataSource = [];
    });
  },
  methods: {
    getList() {
      this.$api.get(`/v1/user/anchor/query?uid=${this.search}`).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.dataSource = res.data.list.map((d) => {
            d.key = d.id;
            return d;
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-search-card-container {
  .container {
    position: relative;
    .title {
      padding-top: 20px;
      margin-left: 30px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      display: flex;
      align-items: flex-end;
      .num-box {
        font-size: 18px;
        margin-left: 11px;
        line-height: 32px;
      }
    }
    .table-box {
      margin: 30px;
    }
  }
}

.name-box {
  display: flex;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
  .dashboard-search-card-container {
    .container {
      .title {
        padding-top: 10px;
        margin-left: 20px;
        font-size: 20px;
        .num-box {
          font-size: 18px;
          margin-left: 5px;
          line-height: 22px;
        }
      }
      .table-box {
        margin: 20px;
        overflow-x: scroll;
      }
    }
  }
}
</style>
