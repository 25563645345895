<template>
  <div class="dashboard-table-card-container">
    <le-card width="100%" style="padding-bottom: 20px">
      <template>
        <div class="container">
          <div class="title-box">
            <div class="card-title" style="display: flex; padding-top: 0px">
              入会记录
            </div>
          </div>

          <div class="content-box" style="display: flex"  @keypress="keyPress($event)"  >
            <div style="flex: 1">
              <div class="search-box">

                <div style="display: flex; align-items: center;">
                  昵称/UID：
                <a-input
                  enter-button="搜索"
                  style="width: 200px"
                  placeholder="请输入搜索内容"
                  @search="onSearch"
                  :allowClear="true"
                  v-model="keyword"
                >
                </a-input>
                </div>
                <div
                  style="display: flex; align-items: center; margin-left: 20px"
                >
                  经纪人：
                  <a-select
                    default-value="0"
                    v-model="value"
                    style="width: 200px"
                  >
                    <a-select-option :value="0"  > 全部 </a-select-option>
                    <a-select-option :value="item.id"  v-for="item in list" :key="item.id"   > {{item.uname}} </a-select-option>
                  </a-select>
                </div>

                <div style="display: flex;margin-left: 20px;">
                  <le-button
                    txt="查询"
                    width="40px"
                    height="25px"
                    fontSize="14px"
                    style="margin-right: 10px"
                    @btnClick="onSearch()"
                  />
                  <le-button
                    txt="重置"
                    width="40px"
                    height="25px"
                    fontSize="14px"
                    background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"
                    @btnClick="onSearch(1)"
                  />
                </div>
              </div>

              <div class="table-box">
                <a-table
                  :columns="columns"
                  :data-source="dataSource"
                  :loading="loading"
                  :pagination="pageOptions"
                  @change="handleTableChange"
                  size="middle"
                >
                  <div slot="uname" slot-scope="row">
                    <a
                      class="name-box"
                      :href="'https://space.bilibili.com/' + row.bili_id"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div class="name-uid">
                        <div>{{ row.uname }}</div>
                        <div>{{ row.bili_id }}</div>
                      </div>
                    </a>
                  </div>

                  <div slot="face" slot-scope="row">
                    <img  style="width: 40px;height:40px;border-radius: 50%;" :src="row.face" alt="" srcset=""  />
                  </div>

                  <div slot="superior" slot-scope="row">
                    <div class="name-uid">
                      <div>{{ row.superior }}</div>
                      <div>{{ row.superior_uid }}</div>
                    </div>
                  </div>
                </a-table>
              </div>
            </div>

            <div style="text-align: center;">
              <div style="width: 500px;height: 600px;" id="main"></div>
              <p>最近7日，跟进主播、入会主播数据总体分布图</p>
            </div>
          </div>
        </div>
      </template>
    </le-card>
  </div>
</template>

<script>
const columns = [
  {
    title: "昵称/UID",
    scopedSlots: { customRender: "uname" },
    width: 200,
  },
  {
    title: "头像",
    scopedSlots: { customRender: "face" },
  },
  {
    title: "入会时间",
    width: 150,
    dataIndex: "created_at",
  },
  {
    title: "操作人",
    width: 150,
    dataIndex: "user.uname",
  },
];
import tool from "@/utils/tool";
import { MixinList } from "./mixin";
import moment from "moment";
// import VeLine from 'v-charts/lib/line.common'
// import VeLine from 'v-charts/lib/line'
// import VCharts from 'v-charts'
// require('@/common/echarts.min.js');
import * as echarts from 'echarts';

export default {
  data() {
    return {
      columns,
      dataUrl: "v2/anchor/guildList",
      delUrl: "admin/layout/del",
      visible: false,
      dateData: {
        start: "",
        end: "",
      },
      dates:[],
      type: "uname",
      keyword: "",
      list:[],
      value: 0
    };
  },
  computed: {},
  mixins: [MixinList],
  created() {
    for(let i=1;i<8;i++){
      this.dates.unshift(moment(new Date())
      .add(-i, "days")
      .format("MM-DD"))
    }
    
    this.dateData.start = moment(new Date())
      .add(-1, "days")
      .format("YYYY-MM-DD 00:00:00");
    this.dateData.end = moment(new Date())
      .add(-1, "days")
      .format("YYYY-MM-DD 23:59:59");

    // 获取所有用户
    this.getAllUsers()
  },
  beforeMount() {},
  mounted() {
    // 获取图表数据
    this.guildData()
  },
  methods: {
    //监听回车键
    keyPress(e){
      if(e.charCode==13){
        this.onSearch()
      }
    },

    getAllUsers(){
      this.$api.get(`/v2/user/all`).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.list=res.data
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //搜索
    onSearch(value) {
      if (value==1) {
        this.keyword=''
        this.value=0
        delete this.queryParam.keyword;
        delete this.queryParam.uid;
      } else {
        this.queryParam.keyword = this.keyword;
        this.queryParam.uid = this.value;
      }
      this.queryParam.page = 1;
      console.log(this.queryParam);
      this.getData();
    },
    //处理图表展示
    guildData() {
      this.$api.get(`/v2/anchor/guildData`).then((res) => {
        console.log(res);
        if (res.code == 200) {
          // this.list=res.data
          let dates = []
          let data1 = []
          let data2 = []

          for (let i = 0; i < res.data.length; i++) {
            dates.push(res.data[i].key)
            data1.push(res.data[i].enter_guild_num)
            data2.push(res.data[i].root_into_num)
          }

          var chartDom = document.getElementById('main');
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: dates
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name:'跟进主播',
                data: data2,
                type: 'line',
                smooth:true
              },
              {
                name:'入会主播',
                data: data1,
                type: 'line',
                smooth:true,
                color:'#ff0000'
              }
            ]
          };

          option && myChart.setOption(option);

        } else {
          this.$message.error(res.message);
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>


.dashboard-table-card-container {
  .container {
    position: relative;
    .title-box {
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
    }
    .search-box {
      display: flex;
      align-items: center;
      margin: 20px 30px;
    }

    .table-box {
      margin: 40px 30px;
      margin-top: 10px;
    }
  }
}

.btn-canle {
  width: 70px;
  height: 30px;
  background: linear-gradient(-10deg, #fd8a8f, #fa6a9d);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin-left: 10px;
}

.login {
  cursor: pointer;
  &:hover {
    color: #008df0;
  }
}

.name-box {
  display: flex;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
  .dashboard-table-card-container {
    .container {
      .title {
        padding-top: 10px;
        margin-left: 20px;
        font-size: 20px;
      }

      .table-box {
        margin: 20px;
        overflow-x: scroll;
      }
    }
  }
}
</style>
