<template>
    <div class="dashboard-search-card-container ">
      <le-card width="100%" height="200px" >
        <template>
          <div class="container">
            <div class="title-box">
              <div class="card-title" style="padding-top: 0;"  >我的主播概况</div>
            </div>

            <!-- <div class="tips-box">统计时间：2022-07-25 00:00~2022-07-26 00:00(今日数据有一定延迟，以次日结果为准)</div> -->
            <div class="card-box">
              <card  
                title="总流水"
                :num="json.total_income"
                tips="时间段总交易流水"
                unit="元"
              />
              <card 
                title="开播主播数"
                :num="json.total_anchor"
                tips="时间段旗下主播开播数量"
                unit="人"
              />
            </div>
          </div>
        </template>
      </le-card>
    </div>
</template>

<script>
import tool from '@/utils/tool'
import card from './card.vue'
export default {
  components: { 
    card
  },
  data() {
    return {
      json:{
        total_anchor:0,
        total_income:0
      }
    };
  },
  computed: {
  },
  mounted(){
    tool.$on('statistics',(res)=>{
      this.json=res;
    })
  },
  methods: {
    
  }
};
</script>

<style lang="less" scoped>
.dashboard-search-card-container {
.container{
  position: relative;
  .title-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  .tips-box{
    margin-left: 30px;
    font-size: 12px;
    color: #aaa;
  }

  .card-box{
    display: flex;
    margin-top: 10px;
  }
}
}


@media screen and(max-width: 750px) {
.dashboard-search-card-container {
.container{
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 16px;
  }
}
}
}

</style>
